import { BodyType, BreastSize, FieldType, HairColor, HipType, InterestType, IProfile, Sex } from 'api/users/models/user';
import { IconName, IconType } from 'components/Icon';
import { TxtType, TxtWeight } from 'components/Txt';
import Autocomplete from 'components/Autocomplete';
import { Size } from 'core/styles';
import type { PropType } from 'vue';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { USER_STORE_KEY, UsersGetter, useUsersGetter } from 'store/users';
import { isNumber } from 'core/helpers';

import type { Nullable } from 'core/types';
import { ICity } from 'core/types';
import { IUserFilter } from 'api/users/models/user/interfaces/IUserFilter';
import { PhotosType } from 'api/users/models/user/enums/PhotosType';
import { useStore } from 'vuex';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';

type NullableStringOrNumber = Nullable<string | number>
type UserFilterValues = Required<
  Pick<IUserFilter, 'age' | 'height' | 'hair' | 'breast' | 'hips' | 'activity' | 'body' | 'interests'>
>

const FilterForm = defineComponent({
  components: {
    Autocomplete,
  },
  props: {
    modelValue: {
      type: Object as PropType<IUserFilter>,
      default: () => ({}),
    },
    hideOnlineFilter: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: undefined,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'submit'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));

    const cities = computed(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

    const getCityById = (id: number) => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.CityById}`](id);

    const shouldValidate = ref(false);
    const minAgeRef = ref();
    const maxAgeRef = ref();
    const minHeightRef = ref();
    const maxHeightRef = ref();

    const filter = ref<IUserFilter>(props.modelValue);
    const city = ref<ICity>((filter.value.city_id && getCityById(filter.value.city_id)));
    if (!city.value) {
      city.value = profile.value.city;
      filter.value.city_id = city.value.id;
    }

    watch(() => filter.value.gender, (gender) => {
      filter.value = {
        ...store.getters[`${USER_STORE_KEY}/${UsersGetter.FilterDefaults}`],
        city_id: city.value.id,
        gender,
      };
    });
    watch(filter, (value) => {
      emit('update:modelValue', {
        ...value,
        city_id: city.value.id,
      });
    }, { deep: true });
    watch(city, (value) => {
      emit('update:modelValue', {
        ...filter.value,
        city_id: city.value.id,
      });
    }, { deep: true });

    const withPhoto = computed({
      get() { return filter.value.photos === PhotosType.required; },
      set(value: boolean) {
        if (value) filter.value.photos = PhotosType.required;
        else filter.value.photos = null;
      },
    });
    const withoutPhoto = computed({
      get() { return filter.value.photos === PhotosType.noPhotos; },
      set(value: boolean) {
        if (value) filter.value.photos = PhotosType.noPhotos;
        else filter.value.photos = null;
      },
    });
    const typicalPhoto = computed({
      get() { return filter.value.photos === PhotosType.typicalPhotos; },
      set(value: boolean) {
        if (value) filter.value.photos = PhotosType.typicalPhotos;
        else filter.value.photos = null;
      },
    });

    const filterValues = computed(() => useUsersGetter<UserFilterValues>(UsersGetter.FilterValues));
    const minAgeValue = computed(() => filterValues.value.age.from);
    const maxAgeValue = computed(() => filterValues.value.age.to);
    const minHeightValue = computed(() => filterValues.value.height.from);
    const maxHeightValue = computed(() => filterValues.value.height.to);

    const minAge = computed({
      get: () => filter.value.age?.from ?? minAgeValue.value,
      set: (v: number) => {
        filter.value.age = { from: v, to: filter.value.age?.to ?? maxAgeValue.value };
      },
    });

    const maxAge = computed({
      get: () => filter.value.age?.to ?? maxAgeValue.value,
      set: (v: number) => {
        filter.value.age = { from: filter.value.age?.from ?? minAgeValue.value, to: v };
      },
    });

    const minHeight = computed({
      get: () => filter.value.height?.from ?? minHeightValue.value,
      set: (v: number) => {
        filter.value.height = { from: v, to: filter.value.height?.to ?? maxHeightValue.value };
      },
    });

    const maxHeight = computed({
      get: () => filter.value.height?.to ?? maxHeightValue.value,
      set: (v: number) => {
        filter.value.height = { from: filter.value.height?.from ?? minHeightValue.value, to: v };
      },
    });

    const rules = reactive({
      minAge: (v: NullableStringOrNumber) =>
        (
          isNumber(v) && v as number >= minAgeValue.value
          && v as number <= maxAge.value
          && v as number <= maxAgeValue.value
        ) || t('rule.incorrect'),
      maxAge: (v: NullableStringOrNumber) =>
        (
          isNumber(v) && v as number >= Math.max(minAge.value, minAgeValue.value)
          && v as number <= maxAgeValue.value
        ) || t('rule.incorrect'),
      minHeight: (v: NullableStringOrNumber) =>
        (
          isNumber(v) && v as number >= minHeightValue.value
          && v as number <= maxHeight.value
          && v as number <= maxHeightValue.value
        ) || t('rule.incorrect'),
      maxHeight: (v: NullableStringOrNumber) =>
        (
          isNumber(v) && v as number >= Math.max(minHeight.value, minHeightValue.value)
          && v as number <= maxHeightValue.value
        ) || t('rule.incorrect'),
    });

    function validate() {
      shouldValidate.value = true;

      if (!minAgeRef.value || !maxAgeRef.value || !minHeightRef.value || !maxHeightRef.value) return false;

      const minAgeValidate = minAgeRef.value.validate();
      const maxAgeValidate = maxAgeRef.value.validate();
      const minHeightValidate = minHeightRef.value.validate();
      const maxHeightValidate = maxHeightRef.value.validate();

      if (!minAgeValidate) {
        minAgeRef.value.focus();
      } else if (!maxAgeValidate) {
        maxAgeRef.value.focus();
      } else if (!minHeightValidate) {
        minHeightRef.value.focus();
      } else if (!maxHeightValidate) {
        maxHeightRef.value.focus();
      }

      return minAgeValidate && maxAgeValidate && minHeightValidate && maxHeightValidate;
    }

    function onSubmit() {
      if (validate()) {
        emit('submit', {
          ...filter.value,
          city_id: city.value.id,
        });
      }
    }

    const handleSex = (sex: Sex) => {
      filter.value.gender = sex;
    };

    const handleHairColor = (color: HairColor) => {
      if (!filter.value.hair) {
        filter.value.hair = [];
      }

      if (filter.value.hair.includes(color)) {
        const index = filter.value.hair.indexOf(color);

        filter.value.hair.splice(index, 1);
      } else {
        filter.value.hair.push(color);
      }
    };

    const handleBreastSize = (size: BreastSize) => {
      if (!filter.value.breast) {
        filter.value.breast = [];
      }

      if (filter.value.breast.includes(size)) {
        const index = filter.value.breast.indexOf(size);

        filter.value.breast.splice(index, 1);
      } else {
        filter.value.breast.push(size);
      }
    };

    const handleHipType = (hip: HipType) => {
      if (!filter.value.hips) {
        filter.value.hips = [];
      }

      if (filter.value.hips.includes(hip)) {
        const index = filter.value.hips.indexOf(hip);

        filter.value.hips.splice(index, 1);
      } else {
        filter.value.hips.push(hip);
      }
    };

    const handleFieldType = (field: FieldType) => {
      if (!filter.value.activity) {
        filter.value.activity = [];
      }

      if (filter.value.activity.includes(field)) {
        const index = filter.value.activity.indexOf(field);

        filter.value.activity.splice(index, 1);
      } else {
        filter.value.activity.push(field);
      }
    };

    const handleBodyType = (body: BodyType) => {
      if (!filter.value.body) {
        filter.value.body = [];
      }

      if (filter.value.body.includes(body)) {
        const index = filter.value.body.indexOf(body);

        filter.value.body.splice(index, 1);
      } else {
        filter.value.body.push(body);
      }
    };

    const handleInterests = (interest: InterestType) => {
      if (!filter.value.interests) {
        filter.value.interests = [];
      }

      if (filter.value.interests.includes(interest)) {
        const index = filter.value.interests.indexOf(interest);

        filter.value.interests.splice(index, 1);
      } else {
        filter.value.interests.push(interest);
      }
    };

    const pickedHairs = computed(() => {
      const gender = filter.value.gender !== Sex.Male ? Sex.Female : Sex.Male;
      const hairs: string[] = [];
      if (filter.value.hair) {
        filter.value.hair.forEach(
          (v: string) => v !== HairColor.None && hairs.push(t(`user.hairColor.${v}.${gender}`, 40)),
        );
      }
      return hairs;
    });

    const hipMap = {
      [HipType.Small]: IconName.HipSmall,
      [HipType.Middle]: IconName.HipMiddle,
      [HipType.Round]: IconName.HipRound,
    };

    const fieldMap = {
      [FieldType.Finance]: '💵',
      [FieldType.Tourism]: '🏕️',
      [FieldType.Education]: '🎓',
      [FieldType.It]: '💻',
      [FieldType.Medicine]: '💉',
      [FieldType.Trade]: '📠',
      [FieldType.Industry]: '🏭',
      [FieldType.Sport]: '⚽',
      [FieldType.Art]: '🎨',
      [FieldType.Other]: '🙌🏽',
    };

    const bodyMap = {
      [BodyType.Thin]: IconName.Chest1,
      [BodyType.Athletic]: IconName.Chest2,
      [BodyType.Paunchy]: IconName.Chest3,
    };

    const interestsMap = {
      [InterestType.Books]: '📕',
      [InterestType.Movies]: '🎬',
      [InterestType.Music]: '🎸',
      [InterestType.Art]: '🎨',
      [InterestType.Architecture]: '🏢',
      [InterestType.Design]: '◼️',
      [InterestType.ForeignLanguages]: '👅',
      [InterestType.Fashion]: '👠',
      [InterestType.Dancing]: '💃🏻',
      [InterestType.Cooking]: '🍕',
      [InterestType.Astrology]: '🔮',
      [InterestType.Psychology]: '❤️',
      [InterestType.Space]: '✨',
      [InterestType.Sport]: '🏆',
      [InterestType.Extreme]: '⚡️',
      [InterestType.Games]: '🎮',
      [InterestType.Travel]: '🏔',
      [InterestType.Cars]: '🚗',
      [InterestType.Aviation]: '✈️',
      [InterestType.Yachting]: '🛳',
      [InterestType.Business]: '💼',
      [InterestType.Politics]: '🗣',
      [InterestType.Investments]: '💰',
      [InterestType.Technology]: '💻',
      [InterestType.Science]: '🎓',
    };

    return {
      city,
      cities,

      shouldValidate,
      minAgeRef,
      maxAgeRef,
      minHeightRef,
      maxHeightRef,

      rules,

      minAgeValue,
      maxAgeValue,
      minHeightValue,
      maxHeightValue,

      minAge,
      maxAge,
      minHeight,
      maxHeight,

      filter,
      pickedHairs,
      filterValues,
      withPhoto,
      typicalPhoto,
      withoutPhoto,

      hipMap,
      fieldMap,
      bodyMap,
      interestsMap,

      onSubmit,
      handleSex,
      handleHairColor,
      handleBreastSize,
      handleHipType,
      handleFieldType,
      handleBodyType,
      handleInterests,

      TxtType,
      TxtWeight,
      Size,
      Sex,
      IconType,
    };
  },
});

export default FilterForm;
