<template>
  <section class="catalog-filter-form">
    <slot name="header" />
    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.createStory.city') }}
      </txt>

      <div class="create-story-form__text">
        <autocomplete
          ref="cityRef"
          v-model="city"
          :label="$t('pages.signup.city')"
          :items="cities"
          two-line
          no-filter
          item-value="id"
          item-text="name"
          select
        >
          <template #item="{ item }">
            <div>{{ item.name }}</div>
            <div>{{ item.region }}</div>
          </template>
        </autocomplete>
      </div>
    </div>

    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.sex') }}
      </txt>

      <div class="catalog-filter-form-sex catalog-filter-form-fields">
        <btn
          :class="{
            'catalog-filter-form-fields__field': true,
            'catalog-filter-form-sex__button': true,
            'catalog-filter-form-sex__button_not-active': filter.gender !== Sex.Female,
          }"

          @click="handleSex(Sex.Female)"
        >
          {{ $t(`user.sex.${Sex.Female}`) }}
        </btn>

        <btn
          :class="{
            'catalog-filter-form-fields__field': true,
            'catalog-filter-form-sex__button': true,
            'catalog-filter-form-sex__button_not-active': filter.gender !== Sex.Male,
          }"

          @click="handleSex(Sex.Male)"
        >
          {{ $t(`user.sex.${Sex.Male}`) }}
        </btn>
      </div>
    </div>

    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.age') }}
      </txt>

      <div
        class="catalog-filter-form-fields"
        style="height: 65px;"
      >
        <textfield
          ref="minAgeRef"
          v-model.number="minAge"
          class="catalog-filter-form-fields__field catalog-filter-form-fields__field_text"
          :lt="$t('pages.form.from')"
          :rt="$t('pages.form.years')"
          :rules="[rules.minAge]"
          inputmode="numeric"
        />

        <textfield
          ref="maxAgeRef"
          v-model.number="maxAge"
          class="catalog-filter-form-fields__field catalog-filter-form-fields__field_text"
          :lt="$t('pages.form.to')"
          :rt="$t('pages.form.years')"
          :rules="[rules.maxAge]"
          inputmode="numeric"
        />
      </div>
    </div>

    <div
      v-if="!hideOnlineFilter"
      class="catalog-filter-form__row"
    >
      <div class="catalog-filter-form__toogler-label">
        <txt
          class="catalog-filter-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.form.online') }}
        </txt>

        <!-- <txt class="catalog-filter-form__subtitle">
          {{ $t('pages.catalog.filter.buyOnline') }}
        </txt> -->
      </div>

      <toggler
        v-model="filter.online"
        class="catalog-filter-form__toogler-form"
      />
    </div>

    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.hairColor') }}
      </txt>

      <tag
        v-for="color in filterValues.hair"
        :key="color"
        clickable
        :active="filter.hair && filter.hair.includes(color)"
        class="catalog-filter-form__tag"
        @click="handleHairColor(color)"
      >
        <template #marker>
          <div :class="`color color_${color}`" />
        </template>

        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $tc(`user.hairColor.${color}.${filter.gender}`) }}
        </txt>
      </tag>
    </div>

    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.height') }}
      </txt>

      <div
        class="catalog-filter-form-fields"
        style="height: 65px;"
      >
        <textfield
          ref="minHeightRef"
          v-model.number="minHeight"
          class="catalog-filter-form-fields__field catalog-filter-form-fields__field_text"
          :lt="$t('pages.form.from')"
          :rules="[rules.minHeight]"
          inputmode="numeric"
        />

        <textfield
          ref="maxHeightRef"
          v-model.number="maxHeight"
          class="catalog-filter-form-fields__field catalog-filter-form-fields__field_text"
          :lt="$t('pages.form.to')"
          :rules="[rules.maxHeight]"
          inputmode="numeric"
        />
      </div>
    </div>

    <div
      v-if="filter.gender === Sex.Female"
      class="catalog-filter-form__row"
    >
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.breastSize') }}
      </txt>

      <tag
        v-for="s in filterValues.breast"
        :key="s"
        short
        clickable
        :active="filter.breast && filter.breast.includes(s)"
        class="catalog-filter-form__tag catalog-filter-form__tag--size"
        :size="Size.L"
        @click="handleBreastSize(s)"
      >
        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.breast.${s}`) }}
        </txt>
      </tag>
    </div>

    <div
      v-if="filter.gender === Sex.Female"
      class="catalog-filter-form__row"
    >
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.hipType') }}
      </txt>

      <tag
        v-for="hip in filterValues.hips"
        :key="hip"
        clickable
        :active="filter.hips && filter.hips.includes(hip)"
        class="catalog-filter-form__tag"
        @click="handleHipType(hip)"
      >
        <template #marker>
          <icon
            :type="IconType.Fill"
            :name="hipMap[hip]"
            :size="Size.M"
          />
        </template>

        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.hip.${hip}`) }}
        </txt>
      </tag>
    </div>

    <div
      v-if="filter.gender === Sex.Male"
      class="catalog-filter-form__row"
    >
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.fieldType') }}
      </txt>

      <tag
        v-for="field in filterValues.activity"
        :key="field"
        clickable
        :active="filter.activity && filter.activity.includes(field)"
        class="catalog-filter-form__tag"
        @click="handleFieldType(field)"
      >
        <template #marker>
          {{ fieldMap[field] }}
        </template>

        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.field.${field}`) }}
        </txt>
      </tag>
    </div>

    <div
      v-if="filter.gender === Sex.Male"
      class="catalog-filter-form__row"
    >
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.bodyType') }}
      </txt>

      <tag
        v-for="body in filterValues.body"
        :key="body"
        clickable
        :active="filter.body && filter.body.includes(body)"
        class="catalog-filter-form__tag"
        @click="handleBodyType(body)"
      >
        <template #marker>
          <icon
            :type="IconType.Fill"
            :name="bodyMap[body]"
            :size="Size.M"
          />
        </template>

        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.body.${body}`) }}
        </txt>
      </tag>
    </div>

    <div class="catalog-filter-form__row">
      <txt
        class="catalog-filter-form__title"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.form.interests') }}
      </txt>

      <tag
        v-for="interest in filterValues.interests"
        :key="interest"
        clickable
        :active="filter.interests && filter.interests.includes(interest)"
        class="catalog-filter-form__tag"
        @click="handleInterests(interest)"
      >
        <template #marker>
          {{ interestsMap[interest] }}
        </template>

        <txt
          :weight="TxtWeight.Medium"
          :size="Size.L"
        >
          {{ $t(`user.interest.${interest}`) }}
        </txt>
      </tag>
    </div>

    <div class="catalog-filter-form__row">
      <div class="catalog-filter-form__toogler-label">
        <txt
          class="catalog-filter-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.form.photo') }}
        </txt>
      </div>

      <toggler
        v-model="withPhoto"
        class="catalog-filter-form__toogler-form"
      />
    </div>

    <div class="catalog-filter-form__row">
      <div class="catalog-filter-form__toogler-label">
        <txt
          class="catalog-filter-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.form.typicalPhoto') }}
        </txt>
      </div>

      <toggler
        v-model="typicalPhoto"
        class="catalog-filter-form__toogler-form"
      />
    </div>

    <div class="catalog-filter-form__row">
      <div class="catalog-filter-form__toogler-label">
        <txt
          class="catalog-filter-form__title"
          :type="TxtType.Heading4"
        >
          {{ $t('pages.form.withoutPhoto') }}
        </txt>
      </div>

      <toggler
        v-model="withoutPhoto"
        class="catalog-filter-form__toogler-form"
      />
    </div>

    <div class="catalog-filter-submit">
      <btn
        w="100%"
        :disabled="isFetching"
        @click="onSubmit"
      >
        {{ submitText || $t('pages.catalog.filter.submit') }}
      </btn>
    </div>

    <slot
      name="footer"
      :filters="filter"
      :hairs="pickedHairs"
    />
  </section>
</template>

<script lang="ts" src="./FilterForm.ts"></script>
<style lang="scss" src="./filter-form.scss"></style>
