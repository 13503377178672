<template>
  <slot
    name="header"
  />
  <modal-header
    :title="title"
    :action-text="actionText"
    class="filter-layout__header"
    :dir="dir"
    @close="$emit('close')"
    @action="$emit('action')"
  />
  <main class="filter-layout__main">
    <slot />
  </main>
</template>

<script lang="ts" src="./FilterLayout.ts" />
<style lang="scss" src="./filterLayout.scss" />
