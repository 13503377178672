import { defineComponent } from 'vue';
import { Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import AboutPaidServices from 'components/AboutPaidServices';
import { StorageKeys } from 'storage';

const About = defineComponent({
  components: {
    AboutPaidServices,
  },
  emits: ['submit'],
  setup(_, { emit }) {
    const serviceSettings = useServiceSettings(ServiceNames.Subscription);

    function handleSubmit() {
      emit('submit');
    }

    return {
      serviceSettings,
      handleSubmit,

      StorageKeys,
      Size,
      TxtType,
      TxtWeight,
    };
  },
});

export default About;
