<template>
  <prompt
    :model-value="modelValue"
    :title="serviceInfo.boughtText || $t('components.createSubscription.title', { price: formatCurrency(serviceInfo.price) })"
    ok-text-path="components.createSubscription.confirm"
    @ok="createSubscription"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
  <insufficient-prompt v-model="insufficientPrompt" />
</template>

<script lang="ts" src="./CreateSubscription.ts" />
