import { computed, defineComponent, ref } from 'vue';
import Prompt from 'components/Prompt';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IProfile } from 'api/users/models/user';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { SubscriptionAction, SUBSCRIPTIONS_STORE_KEY } from 'store/subscriptions';
import { useStore } from 'vuex';
import InsufficientPrompt from 'components/InsufficientPrompt';
import { PropType } from '@vue/runtime-core';
import { ICreateSubscription } from 'api/subscriptionsService';
import { useApiErrors } from 'composables/apiErrors';
import { useCurrency } from 'composables/currency';

const CreateSubscription = defineComponent({
  components: {
    Prompt,
    InsufficientPrompt,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object as PropType<ICreateSubscription>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'success'],
  setup(props, { emit }) {
    const store = useStore();

    const { showError } = useApiErrors();

    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`] as IProfile);

    const serviceInfo = useServiceSettings(ServiceNames.Subscription);
    const confirmPrompt = ref(false);
    const insufficientPrompt = ref(false);

    async function createSubscription() {
      if (!serviceInfo.value.isBought && profile.value.balance < serviceInfo.value.price) {
        insufficientPrompt.value = true;
        return;
      }
      try {
        await store.dispatch(`${SUBSCRIPTIONS_STORE_KEY}/${SubscriptionAction.CreateSubscription}`, {
          age: props.filter.age,
          gender: props.filter.gender,
          height: props.filter.height,
          hair: props.filter.hair,
          breast: props.filter.breast,
          hips: props.filter.hips,
          activity: props.filter.activity,
          body: props.filter.body,
          interests: props.filter.interests,
          photos: props.filter.photos,
          city_id: props.filter.city_id,
          name: props.filter.name,
        });
        emit('update:modelValue', false);
        emit('success', false);
      } catch (e) {
        showError(e);
      }
    }

    const { formatCurrency } = useCurrency();

    return {
      confirmPrompt,
      insufficientPrompt,
      serviceInfo,
      formatCurrency,

      createSubscription,
    };
  },
});

export default CreateSubscription;
