<template>
  <about-paid-services
    :local-storage-key="StorageKeys.AboutSubscriptionViewed"
    :button-title="$t('components.aboutSubscriptions.submit')"
    @submit="handleSubmit"
  >
    <template #default="{ themeColor }">
      <txt :type="TxtType.Heading2">{{ $t('components.aboutSubscriptions.title') }}</txt>
      <p class="my-4">{{ $t('components.aboutSubscriptions.description') }}</p>
      <txt
        :type="TxtType.Heading4"
        class="mb-4"
      >
        {{ $t('components.aboutSubscriptions.listTitle') }}
      </txt>
      <ul>
        <li class="subscription-about__li">
          <span class="subscription-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-1.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-1.svg"
            >
            <img
              v-else
              src="./assets/blue-1.svg"
            >
          </span>
          {{ $t('components.aboutSubscriptions.li1') }}
        </li>
        <li class="subscription-about__li">
          <span class="subscription-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-2.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-2.svg"
            >
            <img
              v-else
              src="./assets/blue-2.svg"
            >
          </span>
          {{ $t('components.aboutSubscriptions.li2') }}
        </li>
        <li class="subscription-about__li">
          <span class="subscription-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-3.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-3.svg"
            >
            <img
              v-else
              src="./assets/blue-3.svg"
            >
          </span>
          {{ $t('components.aboutSubscriptions.li3') }}
        </li>
        <li class="subscription-about__li">
          <span class="subscription-about__icon-wrapper">
            <img
              v-if="themeColor === 'lavender'"
              src="./assets/lavender-4.svg"
            >
            <img
              v-else-if="themeColor === 'red'"
              src="./assets/red-4.svg"
            >
            <img
              v-else
              src="./assets/blue-4.svg"
            >
          </span>
          {{ $t('components.aboutSubscriptions.li4') }}
        </li>
      </ul>
      <p>{{ $tc('components.aboutSubscriptions.price', serviceSettings.price) }}</p>
      <p>{{ $tc('components.aboutSubscriptions.duration', serviceSettings.days) }}</p>
    </template>
  </about-paid-services>
</template>

<script lang="ts" src="./About.ts" />
<style lang="scss" src="./about.scss" />
