import { defineComponent, PropType } from 'vue';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';

const FilterLayout = defineComponent({
  components: {
    ModalHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: '',
    },
    dir: {
      type: String as PropType<ModalHeaderDirection>,
      default: '',
    },
  },
  emits: ['close', 'action'],
});

export default FilterLayout;
